import React, { useState } from "react";
import styled from "styled-components";
import { Wrapper } from "../css/section";
import { projects } from "../data";

const SubWrapper = styled.div`
	/* border: 1px solid white; */
	padding: 0 !important;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto;
	justify-content: center;
	align-items: center;
	.image-container {
		//border: 1px solid yellow;
		position: relative;
		width: 50rem;
		height: 40rem;
		cursor: pointer;

		a {
		}
		.project-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.button {
			color: rgb(220, 220, 250);
			font-size: 3rem;
			background-color: rgba(19, 19, 19, 1);
			border: none;
			position: absolute;
			padding: 1rem 2rem;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			/* border: 1px solid red; */
		}
		.button:hover {
			color: rgb(190, 160, 160);
			/* font-size: 3.1rem; */
		}
		.prev-button {
			top: -1rem;
			left: -1rem;
			z-index: 5;
		}
		.next-button {
			bottom: -1rem;
			right: -1rem;
			z-index: 5;
		}
	}
	.text-container {
		/* border: 1px solid yellow; */
		align-self: start;
		justify-self: left;
		margin: 0;
		padding: 0;
		.project-text {
			color: rgb(220, 220, 250);
			text-transform: capitalize;
			font-size: 2rem;
			padding-top: 3rem;
		}
	}
	@media screen and (max-width: 800px) {
		grid-template-columns: auto;
		.image-container {
			width: 45rem;
			height: 35rem;
		}
		.text-container {
			justify-self: center;
		}
	}
	@media screen and (max-width: 600px) {
		.image-container {
			width: 40rem;
			height: 30rem;
		}
	}
	@media screen and (max-width: 500px) {
		.image-container {
			width: 35rem;
			height: 25rem;
		}
	}
`;

const Projects = () => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const increaseIndex = () => {
		console.log("increase");
		if (selectedIndex < projects.length - 1) {
			setSelectedIndex((oldState) => oldState + 1);
		}
	};

	const decreaseIndex = () => {
		console.log("decrease");
		if (selectedIndex > 0) {
			setSelectedIndex((oldState) => oldState - 1);
		}
	};

	console.log(selectedIndex);

	return (
		<Wrapper id="projects">
			<div>
				<div className="relative-container">
					<h1>Projects</h1>
					<h2>some of my work</h2>
				</div>

				<SubWrapper>
					<div className="image-container">
						<button onClick={decreaseIndex} className="button prev-button">
							&#x3c;&#x3c;
						</button>
						<a href={projects[selectedIndex].url} target="_blank">
							<img src={projects[selectedIndex].imageSrc} alt="" className="project-image" />
						</a>
						<button onClick={increaseIndex} className="button next-button">
							&#x3e;&#x3e;
						</button>
					</div>
					<div className="text-container">
						<p className="project-text">{projects[selectedIndex].title}</p>
					</div>
				</SubWrapper>
			</div>
		</Wrapper>
	);
};

export default Projects;
