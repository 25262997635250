import mealPj from "./images/meal-project.png";
import spacePj from "./images/space.png";
import rijksPj from "./images/masterRijks.png";

export const projects = [
	{
		title: "space and astronomy",
		imageSrc: spacePj,
		url: "https://space-db-project.netlify.app/apod",
	},
	{
		title: "the masters of the rijksmuseum",
		imageSrc: rijksPj,
		url: "https://rijks-project.netlify.app/",
	},
	{
		title: "meals and recipes",
		imageSrc: mealPj,
		url: "https://the-meal-db-project.netlify.app/",
	},
];
