import React from "react";
import styled from "styled-components";

const Wrapper = styled.aside`
	z-index: 10;
	margin-top: 1.5rem;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	color: rgb(190, 160, 160);
	display: block;
	font-size: 20px;
	/* font-family: "Anta", sans-serif; */
	font-weight: 400;
	font-style: normal;
	letter-spacing: 1rem;
	padding-left: 25rem;

	h2 {
		/* border: 1px solid white; */
		/* background-color: #ccc; */
		cursor: pointer;
		height: 50px;
		margin: 0;
		padding: 0;
		cursor: pointer;
		overflow: hidden;
	}

	h2 span {
		/* border: 1px solid red; */
		display: flex;
		height: 50px;
	}
	h2:hover span {
		animation: shuffle steps(10) 1s 1;
	}
	@keyframes shuffle {
		0% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(-500px);
		}
	}
	@media screen and (max-width: 1300px) {
		padding-left: 0;
	}
`;

const Shuffler = () => {
	return (
		<Wrapper>
			<h2>
				<span>_HADLEY</span>
				<span>_H#D*EY</span>
				<span>_HA4L-Y</span>
				<span>_7AD^EY</span>
				<span>_H%DL\Y</span>
				<span>_HA?LE_</span>
				<span>_HADL!Y</span>
				<span>_HtDLEY</span>
				<span>_HAD$EY</span>
				<span>_HADLEY</span>
			</h2>
		</Wrapper>
	);
};

export default Shuffler;
