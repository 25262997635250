import React from "react";
import { Wrapper } from "../css/section";
import { FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const Contact = () => {
	const year = new Date().getFullYear();

	return (
		<Wrapper id="contact">
			<div>
				<div className="relative-container">
					<h1>Contact</h1>
					<h2>interested ?</h2>
				</div>
				<div className="center-container">
					<a href="#">
						<MdOutlineLocationOn />
						Arnhem, Netherlands
					</a>
					<a href="mailto:h.videlier@gmail.com">
						<FiMail />
						h.videlier@gmail.com
					</a>
					<a href="tel:0033603045977">
						<BsTelephone />
						+33 6 03 04 59 77
					</a>
					<div className="social-nets">
						<a href="https://www.linkedin.com/in/hadley-videlier/" target="_blank" className="text-container">
							<BsLinkedin />
						</a>
						<a href="https://github.com/Adlax" target="_blank" className="text-container">
							<FaGithub />
						</a>
					</div>
					<p>{year}</p>
				</div>

				<p className="text-container"></p>
			</div>
		</Wrapper>
	);
};

export default Contact;
