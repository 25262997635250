import React from "react";
import { Wrapper } from "../css/section";

const About = () => {
	return (
		<Wrapper id="about">
			<div>
				<div className="relative-container">
					<h1>About</h1>
					<h2>...myself</h2>
				</div>
				<p className="text-container">
					I am a frontend developer who tries to create user interfaces that are intuitive, beautiful, stylish, and responsive.{" "}
				</p>
			</div>
		</Wrapper>
	);
};

export default About;
