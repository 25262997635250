import styled from "styled-components";

export const Wrapper = styled.section`
	/* border: 3px solid white; */
	padding: 10rem auto;
	margin-left: 25rem;
	z-index: 1;
	/* color: rgb(110, 60, 60); */
	/* color: rgb(60, 110, 90); */
	/* color: rgb(190, 160, 160); */
	div {
		max-width: 80%;
		margin: auto auto;
		text-align: center;
		padding: 5rem 0;

		.relative-container {
			position: relative;
			/* border: 1px solid white; */
			h1 {
				font-size: 20rem;
				font-weight: 300;
				color: rgba(90, 90, 90, 1);
				text-transform: uppercase;
			}
			h2 {
				font-size: 4rem;
				color: rgb(190, 160, 160);
				position: absolute;
				top: 65%;
				left: 50%;
				transform: translate(-50%, -50%);
				/* border: 1px solid white; */
				text-align: center;
				display: inline-block;
				width: 90%;
				font-weight: 200;
			}
		}

		.center-container {
			margin: 0 auto;
			/* border: 2px solid white; */
			width: fit-content;
			display: flex;
			flex-direction: column;
			gap: 3rem;
			align-items: center;
			font-size: 2rem;
			color: rgb(220, 220, 250);

			a:link,
			a:visited {
				font-size: inherit;
				display: flex;
				align-items: center;
				gap: 2rem;
			}

			a:hover,
			a:active {
			}

			.social-nets {
				display: flex;
				gap: 2rem;
				align-items: center;
				padding: 0;
				font-size: 3.5rem;
				a {
					transition: all 0.3s ease-in-out;
				}
				a:hover {
					color: rgb(190, 160, 160);
				}
			}
		}

		.text-container {
			font-size: 2rem;
			color: rgb(220, 220, 250);
			margin-bottom: 5rem;
		}
	}
	@media screen and (max-width: 1700px) {
		div {
			.relative-container {
				h1 {
					font-size: 16rem; // 20
				}
				h2 {
					font-size: 4rem; //4
				}
			}
		}
	}
	@media screen and (max-width: 1300px) {
		margin-left: 0;
		div {
			.relative-container {
				h1 {
					font-size: 13rem; // 20
				}
				h2 {
					font-size: 4rem; //4
				}
			}
		}
	}
	@media screen and (max-width: 1000px) {
		margin-left: 0;
		div {
			.relative-container {
				h1 {
					font-size: 10rem; // 20
				}
				h2 {
					font-size: 3rem; //4
				}
			}
		}
	}
	@media screen and (max-width: 650px) {
		div {
			.relative-container {
				h1 {
					font-size: 7rem; // 20
				}
				h2 {
					font-size: 3rem; //4
				}
			}
		}
	}
	@media screen and (max-width: 500px) {
		div {
			.relative-container {
				h1 {
					font-size: 6.5rem; // 20
				}
				h2 {
					font-size: 3rem; //4
				}
			}
		}
	}
	@media screen and (max-width: 400px) {
		div {
			.relative-container {
				h1 {
					font-size: 5.5rem; // 20
				}
				h2 {
					font-size: 2.5rem; //4
				}
			}
		}
	}
`;
