import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const Wrapper = styled.nav`
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	height: 100vh;
	width: 25rem;
	/* border: 2px solid red; */
	font-size: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: rgb(220, 220, 250);
	font-weight: 400;
	.main-nav {
		display: flex;
		flex-direction: column;
		gap: 15rem;
		justify-content: space-around;
		transition: all 0.3s ease-in-out;
	}

	.symbols {
	}

	a {
		position: relative;
		/* border: 1px solid red; */
	}

	a::before {
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-color: rgb(190, 160, 160);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		white-space: nowrap;
		transition: 0.5s;
		/* overflow: hidden; */
	}

	a:hover::before {
		width: 100%;
	}

	a::after {
		content: "";
		position: absolute;
		bottom: -1rem;
		left: 0%;
		height: 1px;
		width: 24rem;
		background-color: rgb(220, 220, 250);
		/* transform: translate(-50%, 0); */
	}

	.mobile-nav-btn-open-container {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 3rem;
		padding: 0.5rem;
		border-radius: 500px;
		background-color: rgba(19, 19, 19, 1);
		color: rgb(60, 60, 60);
		border: 3px solid rgb(60, 60, 60);
		margin: 1rem;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
	}

	.mobile-nav-btn-close-container {
		/* display: none; */
		position: absolute;
		top: 0;
		right: 0;
		font-size: 3rem;
		padding: 0.5rem;
		border-radius: 500px;
		background-color: rgba(19, 19, 19, 1);
		color: rgb(60, 60, 60);
		border: 3px solid rgb(60, 60, 60);
		margin: 1rem;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
	}

	.mobile-nav-btn-open-container:hover,
	.mobile-nav-btn-close-container:hover {
		border: 3px solid rgb(190, 160, 160);
		.mobile-nav-btn {
			color: rgb(190, 160, 160);
			transform: scale(1.2);
		}
	}

	.mobile-nav-btn {
		display: grid;
		grid-template-columns: 1fr;
		place-content: center;
		font-size: inherit;
		background: transparent;
		color: inherit;
		border: none;
		cursor: inherit;
		transition: all 0.2s ease-in-out;
	}

	.menu-overlay {
		/* border: 2px solid white; */
		background-color: rgba(19, 19, 19, 0.4);
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		backdrop-filter: blur(10px);
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: auto;
		place-content: center;
		transform: translateX(-100%);
		transition: all 0.3s ease-in-out;
		a::after {
			left: 50%;
			transform: translate(-50%, 0);
			width: 100vw;
		}
		.overlay-nav {
			/* border: 2px solid red; */
			padding: 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			list-style: none;
			gap: 15rem;
			justify-content: space-around;
			transition: all 0.3s ease-in-out;
		}
		.contact-center-container {
			/* border: 2px solid red; */
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			font-size: 2rem;
			a {
				display: flex;
				gap: 1rem;
				align-items: center;
			}
			a::before,
			a::after {
				content: none !important;
			}
			.social-nets {
				display: flex;
				gap: 1rem;
			}
		}
	}

	.menu-overlay-open {
		transform: translateX(0);
	}

	@media screen and (max-width: 1300px) {
		width: 70px;
		/* border: 2px dashed pink; */
		.main-nav {
			transform: translateX(-300px);
			/* display: none; */
		}
		.mobile-nav-btn-open-container {
			display: grid;
		}
	}
`;

const Menu = () => {
	const [mobileMenu, setMobileMenu] = useState(false);

	const year = new Date().getFullYear();
	const screenWidth = window.screen.width;
	const screenHeight = window.screen.height;

	window.onresize = () => {
		// console.log("resize");
		const screenWidth = window.screen.width;
		const screenHeight = window.screen.height;
		// console.log(screenWidth, screenHeight);
		if (screenWidth > 1300) {
			setMobileMenu(false);
		}
	};

	const toggleMenu = () => {
		console.log("toggle");
		setMobileMenu((oldState) => !oldState);
	};

	const closeMenu = () => {
		setMobileMenu(false);
	};

	console.log(mobileMenu);

	return (
		<Wrapper>
			<div className="mobile-nav-btn-open-container">
				<button onClick={toggleMenu} className="mobile-nav-btn" name="open-menu">
					<RxHamburgerMenu />
				</button>
			</div>

			<div>
				<ul className="main-nav">
					<li>
						<a href="#about" data-text="&#x229B;About">
							<span className="symbols">&#x229B;</span>About
						</a>
					</li>
					<li>
						<a href="#projects" data-text="&#x229B;Projects">
							<span className="symbols">&#x229B;</span>Projects
						</a>
					</li>
					<li>
						<a href="#contact" data-text="&#x229B;Contact">
							<span className="symbols">&#x229B;</span>Contact
						</a>
					</li>
				</ul>
			</div>

			<div className={`menu-overlay ${mobileMenu ? "menu-overlay-open" : ""}`}>
				<div className="mobile-nav-btn-close-container">
					<button onClick={toggleMenu} className="mobile-nav-btn" name="close-menu">
						<RxCross1 />
					</button>
				</div>
				<ul className="overlay-nav">
					<li>
						<a href="#about" data-text="&#x229B; About &#x229B;" onClick={closeMenu}>
							<span className="symbols">&#x229B;</span> About <span className="symbols">&#x229B;</span>
						</a>
					</li>
					<li>
						<a href="#projects" data-text="&#x229B; Projects &#x229B;" onClick={closeMenu}>
							<span className="symbols">&#x229B;</span> Projects <span className="symbols">&#x229B;</span>
						</a>
					</li>
					<li>
						<a href="#contact" data-text="&#x229B; Contact &#x229B;" onClick={closeMenu}>
							<span className="symbols">&#x229B;</span> Contact <span className="symbols">&#x229B;</span>
						</a>
					</li>
				</ul>
				<div className="contact-center-container">
					<a href="#">
						<MdOutlineLocationOn />
						Arnhem, Netherlands
					</a>
					<a href="mailto:h.videlier@gmail.com">
						<FiMail />
						h.videlier@gmail.com
					</a>
					<a href="tel:0033603045977">
						<BsTelephone />
						+33 6 03 04 59 77
					</a>
					<div className="social-nets">
						<a href="https://www.linkedin.com/in/hadley-videlier/" target="_blank" className="text-container">
							<BsLinkedin />
						</a>
						<a href="https://github.com/Adlax" target="_blank" className="text-container">
							<FaGithub />
						</a>
					</div>
					<p className="year">{year}</p>
				</div>
			</div>
		</Wrapper>
	);
};

export default Menu;
