import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Menu from "./components/Menu";
import Projects from "./components/Projects";
import styled from "styled-components";
import Shuffler from "./components/Shuffler";

const Wrapper = styled.main`
	display: grid;
	grid-template-columns: 1fr;
`;

function App() {
	return (
		<Wrapper>
			<Shuffler />
			<Menu />
			<About />
			<Projects />
			<Contact />
		</Wrapper>
	);
}

export default App;
